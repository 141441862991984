import { toArray } from './dom'

/**
 * @param  {HTMLElement} element
 * @param  {string}      prop
 * @return {string}
 */
const getStyle = (element, prop) => getComputedStyle(element)[prop]

const getTransfrom = (element) => {
  const transform = getStyle(element, 'transform')
  const rePattern = /^matrix\((.+)\)$/
  const vals = transform.match(rePattern)[1].split(',')
  return {
    x: vals[4],
    y: vals[5],
    scaleX: vals[0],
    scaleY: vals[3],
    skewX: vals[2],
    skewY: vals[1]
  }
}

/**
 * @param {HTMLElement|HTMLCollection|NodeList} elements
 * @param {Object}                              props
 */
const setStyle = (elements, props) => {
  toArray(elements).forEach((el) => {
    const style = el.style
    for (const [key, val] of Object.entries(props)) {
      style[key] = val
    }
  })
}

export { getStyle, getTransfrom, setStyle }
