const Opt = {
  duration: 600,
  easing: 'easeOutQuad',
  offset: 0
}

export default class SmoothScroll {
  constructor(element) {
    this.elm = element
    element.addEventListener('click', event => this.onClicked(event))
  }

  onClicked(event) {
    let href = this.elm.getAttribute('href')
    const targetLink = this.elm.href
    const targetHash = this.elm.dataset.hash
    const nowHash = location.hash
    const nowHref = location.href.replace(nowHash, '')
    if (href.indexOf('#') !== 0) {
      if (targetLink === nowHref) {
        href = targetHash
      } else {
        this.elm.href = targetLink + targetHash
        return true
      }
    }
    if (href.length === 0) {
      return true
    }
    event.preventDefault()
    const _elm = href === '#' ? 'html' : href
    this.scroll(_elm)
  }

  scroll(elm) {
    const _elm = document.querySelector(elm)
    const rect = _elm.getBoundingClientRect()
    window.Velocity(_elm, 'stop')
    window.Velocity(_elm, 'scroll', {
      duration: Math.abs(rect.top * 0.2),
      easing: Opt.easing,
      offset: Opt.offset
    })
  }
}
