import getPrefix from 'helpers/getPrefix'
import getScrollBarWidth from 'helpers/getScrollBarWidth'

const barSize = getScrollBarWidth()
const transform = getPrefix(['transform', 'msTransform'])

const util = {
  scrollBarSize: barSize,
  transformStr: transform
}

export default util
