import getParameters from 'helpers/getParameters'

const params = getParameters()

const env = {
  debug: () => {
    return !!params.get('debug') && params.get('debug')
  }
}

export default env
