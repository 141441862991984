const SELECTOR = {
  BUTTON: '[data-nav=button]'
}
const CLASSES = {
  SHOWN: 'is-shown'
}

export default class LocalNavi {
  constructor(element) {
    this.isShown = false
    this.element = element
    this.button = this.element.querySelector(SELECTOR.BUTTON)
    this.button.addEventListener('click', event => this.onClick(event), false)
  }

  onClick(e) {
    return this[!this.isShown ? 'open' : 'close'](e)
  }

  open() {
    this.element.classList.add(...[CLASSES.SHOWN])
    this.isShown = !this.isShown
  }

  close() {
    this.element.classList.remove(CLASSES.SHOWN)
    this.isShown = false
  }
}
