const SELECTOR = {
  SEARCHBUTTON: '.js-search-button',
  SEARCHSUBMIT: '.js-search-submit',
  SEARCHFIELD: '.js-search-field',
  MENUBUTTON: '.js-btn-menu',
  MENUACCORDION: '.js-accordion-menu > li'
}
const CLASSES = {
  OPENED: 'is-opened',
  MENUOPENED: 'is-menu-opened',
  SHOWN: 'is-shown'
}

export default class Header {
  constructor() {
    this.searchIsShown = false
    this.menuIsShown = false
    this.html = document.documentElement
    this.buttonTrigger = document.querySelector('[data-trigger=menu-button]')
    this.searchButton = [...document.querySelectorAll(SELECTOR.SEARCHBUTTON)]
    this.searchSubmit = [...document.querySelectorAll(SELECTOR.SEARCHSUBMIT)]
    this.searchField = document.querySelector(SELECTOR.SEARCHFIELD)
    this.menuButton = [...document.querySelectorAll(SELECTOR.MENUBUTTON)]
    this.menuAccordion = [...document.querySelectorAll(SELECTOR.MENUACCORDION)]

    // Observer
    this.opt = {
      root: null,
      rootMargin: `-45px 0px`,
      threshold: 0
    }
    this.clientH = document.documentElement.clientHeight
    this.observer = new IntersectionObserver(entries => this.observation(entries), this.opt)
    this.observer.observe(this.buttonTrigger)

    // search button
    this.searchButton.forEach(elm => elm.addEventListener('click', event => this.searchClick(event), false))

    // submit button
    this.searchSubmit.forEach(elm => elm.addEventListener('click', event => this.submitClick(event), false))

    // Menu
    this.menuButton.forEach(elm => elm.addEventListener('click', event => this.menuClick(event), false))

    // Menu Accordion
    this.menuAccordion.forEach(elm =>
      elm.addEventListener('click', event => this.onMenuAccordionClick(event, elm), false)
    )
  }

  observation(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.menuButton[0].classList.remove('is-menu-black')
      } else {
        this.menuButton[0].classList.add('is-menu-black')
      }
    })
  }

  searchClick(e) {
    return this[!this.searchIsShown ? '_searchOpen' : '_searchClose'](e)
  }

  submitClick(e) {
    const obj = e.path
    const form = obj.filter(elm => {
      return elm.tagName === 'FORM'
    })
    form[0].submit()
  }

  menuClick(e) {
    return this[!this.menuIsShown ? '_menuOpen' : '_menuClose'](e)
  }

  onMenuAccordionClick(e, _button) {
    if (_button.opened) {
      _button.opened = false
      _button.classList.remove(CLASSES.SHOWN)
    } else {
      _button.opened = true
      _button.classList.add(CLASSES.SHOWN)
    }
  }

  _searchOpen() {
    this.searchIsShown = true
    this.searchField.classList.add(...[CLASSES.OPENED])
  }

  _searchClose() {
    this.searchIsShown = false
    this.searchField.classList.remove(...[CLASSES.OPENED])
  }

  _menuOpen() {
    this.menuIsShown = true
    this.html.classList.add(...[CLASSES.MENUOPENED])
  }

  _menuClose() {
    this.menuIsShown = false
    this.html.classList.remove(...[CLASSES.MENUOPENED])
  }
}
