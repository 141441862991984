/**
 * @return {Map}
 */
const getParameters = () => {
  const params = new Map()
  decodeURIComponent(location.search).replace(
    /([^?=&]+)(=([^&]*))?/g,
    (match, name, valueMatch, value) => {
      params.set(name, value)
    }
  )
  return params
}

export default getParameters
