const CLASSES = {
  SHOWN: 'is-shown'
}

export default class Accordion {
  constructor(elm) {
    this.target = [...elm.querySelectorAll('[data-elm=button]')]
    console.log(this.target)
    this.target.forEach(elm => elm.addEventListener('click', event => this.toggleClick(event), false))
  }

  toggleClick(event) {
    const elm = event.currentTarget.parentNode
    const editClass = elm.classList
    if (editClass.contains(CLASSES.SHOWN)) {
      editClass.remove(CLASSES.SHOWN)
    } else {
      editClass.add(CLASSES.SHOWN)
    }
  }
}
