import '@babel/polyfill'
import 'vendors/particles/particles'
import 'intersection-observer'
import Velocity from 'velocity-animate/velocity.min'
import 'velocity-animate/velocity.ui.min'
import Swiper from 'swiper/js/swiper.min.js'
window.Swiper = Swiper
window.Velocity = Velocity

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
}

import SmoothScroll from 'ui/SmoothScroll'
import Slider from 'ui/Slider'
import LocalNavi from 'ui/LocalNavi'
import Background from 'ui/Background'
import Header from 'ui/Header'
import Validate from 'ui/Validate'
import Accordion from 'ui/Accordion'
import CategoryNavi from 'ui/CategoryNavi'

export default class App {
  constructor() {
    new Background('.js-stage')
    new Header()
    Array.from(
      [
        [SmoothScroll, 'a[href^="#"]'],
        [Slider, '.js-slider'],
        [LocalNavi, '.js-local-nav'],
        [Validate, '.js-validate'],
        [Accordion, '.js-faq-accordion'],
        [CategoryNavi, '.js-category-nav']
      ],
      arr => this.newClass(...arr)
    )
  }

  newClass(addClassName, elm, opts) {
    const element = [...document.querySelectorAll(elm)]
    Array.from(element, _elm => {
      if (opts) {
        new addClassName(_elm, opts)
      } else {
        new addClassName(_elm)
      }
    })
  }
}

new App()
