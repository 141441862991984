const Opt = {
  duration: 600,
  easing: 'easeOutQuad',
  offset: 0
}

export default class SmoothScroll {
  get _selector() {
    return {
      maim: '.js-slider-main',
      next: '.js-slider-next',
      prev: '.js-slider-prev'
    }
  }

  constructor(element) {
    const { _selector } = this
    this.elm = element
    this.main = this.elm.querySelector(_selector.maim)
    this.nextBtn = this.elm.querySelector(_selector.next)
    this.prevBtn = this.elm.querySelector(_selector.prev)
    const Opt = {
      centeredSlides: true,
      slidesPerView: 'auto',
      loop: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: this.nextBtn,
        prevEl: this.prevBtn
      }
    }
    this.controller = new window.Swiper(this.main, Opt)
  }
}
