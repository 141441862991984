import { detect } from 'core'

export default class CategoryNavi {
  get SELECTOR() {
    return {
      SUB: '[data-elm=sub]'
    }
  }

  get CLASSES() {
    return {
      OPENED: 'is-opened'
    }
  }

  constructor(elm) {
    this.mobile = detect.isMobile()
    if (!this.mobile) return true
    this.list = [...elm.children]
    this.list.forEach(elm => {
      const sub = elm.querySelector(this.SELECTOR.SUB)
      if (sub) {
        const link = [...elm.children].filter(elm => elm.tagName === 'A')[0]
        const close = sub.querySelector('button')
        link.addEventListener('click', event => this.toggleClick(event, elm), false)
        close.addEventListener('click', event => this.toggleClick(event, elm), false)
      }
    })
  }

  toggleClick(event, elm) {
    event.preventDefault()
    const { CLASSES } = this
    const editClass = elm.classList
    if (editClass.contains(CLASSES.OPENED)) {
      editClass.remove(CLASSES.OPENED)
    } else {
      editClass.add(CLASSES.OPENED)
    }
  }
}
