export default class Background {
  constructor(elements) {
    this.elm = [...document.querySelectorAll(elements)]
    this.elm.forEach(elm => {
      window.particlesJS.load(elm.id, '/scripts/particles.json', () => {
        // console.log('callback - particles.js config loaded')
      })
    })
  }
}
